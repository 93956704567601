import React, { Component } from "react";
import { Form, Button, Layout, Progress, Input, Timeline } from "antd";
import { ApiService } from "../../services/ApiService";
import ModalLine from "./ModalLine";
import ModalTest from "./ModalTest";
import ModalResult from "./ModalResult";
import styles from "./styles";
import { ErrorMap } from "../../utils/Errors"
const { Item } = Form;

export default class Production extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: "",
      percentUpload: 0,
      buttonStartConfiguration: false,
      timeLineFlow: [],
      productionLineId: null,
      productionLineName: "",
      visibleModalLine: false,
      visibleModalTest: false,
      visibleModalResult: false,
      networkCommands: [],
      generalCommands: [],
      staticCommands: [],
      testCommands: [],
      deviceTypeCommand: null,
      deviceRestartCommand: null,
      deviceInternalMeter: null,
      productionStatus: null,
      producedDeviceId: null,
      manager: null,
      printerNameOne: "",
      printerNameTwo: "",
      totalVirtualAssetToProduce: 0,
    };
    this.api = ApiService();
    this.localhost = ApiService({ baseURL: "http://localhost:3004" });

    this.handleCancel = this.handleCancel.bind(this);
    this.endProduction = this.endProduction.bind(this);
  }

  componentDidMount() {
    const productionLineId = localStorage.getItem("@Urbana:productionLineId");
    const manager = localStorage.getItem("@Urbana:manager");

    this.setState({ productionLineId, visibleModalLine: !productionLineId, manager: manager === 'true' ? false : true });
    if (!!productionLineId) {
      this.getProductionLine(productionLineId);
    }
  }

  handleProduceVirtualAsset = async () => {
    const { api, } = this;
    const { device, productionLineId, totalVirtualAssetToProduce } = this.state;
    this.setState({ timeLineFlow: [], buttonStartConfiguration: true });

    await this.steps("Start production of virtual asset...", 50);

    try {
      for (let index = 0; index < totalVirtualAssetToProduce; index++) {
        let body = { productionLineId, startProduction: new Date() };

        const producedDevice = await api.post(`/v1/devices/${device.id}/production/start`, body)
          .then(({ data }) => { return data.item })
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
          });

        body = {
          endProduction: new Date(),
          success: true,
        }

        await api.post(`/v1/production/${producedDevice.id}/end`, body).catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
        });
      }

      this.steps("Virtual asset produced!", 100);
      this.openModalResult(true, `${totalVirtualAssetToProduce} virtual asset produced`);

    } catch (error) {
      let dataError = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : error;
      let message = dataError.message ? dataError.message : error.toString();
      let errorCode = dataError.code ? dataError.code : 500;
      this.steps(message, 100, false);
      this.openModalResult(false, message, errorCode);
    }
  }

  handleStartConfiguration = async () => {
    const { api, localhost } = this;
    const { device, productionLineId, deviceTypeCommand, deviceRestartCommand, staticCommands, printerNameOne, printerNameTwo } = this.state;
    const deviceId = device.id;

    this.setState({ timeLineFlow: [], buttonStartConfiguration: true });

    try {
      await this.steps("Start production...", 5);

      // check if the local bridge is available and obtain the startProductionDate
      await this.steps("Checking minimum requirements...", 10);
      const productionCheck = await localhost.get(`/production/check`)
        .then(({ data }) => data.item)
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        });

      await this.steps("Checking lora server...", 15);
      await api.get(`/v1/check-lora-server`)
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
        });

      // Download firmware
      await this.steps("Configurating firmware...", 20);
      await localhost.get(`/production/devices/${deviceId}/download-firmware?firmwareName=${device.firmwareName}`)
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        });

      // Upload firmware
      this.steps("Uploading firmware...", 25);
      await localhost.get(`/production/upload-firmware?firmwareName=${device.firmwareName}`)
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        });

      if (!!deviceTypeCommand.length) {
        await this.steps("Checking device type...", 30);
        await localhost.post(`/production/send-commands`, { commands: deviceTypeCommand })
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
          });
      }

      const body = { productionLineId: Number.parseInt(productionLineId), startProduction: new Date() };
      const producedDevice = await api.post(`/v1/devices/${deviceId}/production/start`, body)
        .then(({ data }) => { return data.item })
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
        });

      this.setState({ producedDeviceId: producedDevice.id });

      if (printerNameOne) {
        this.steps("Printing label one...", 35);
        await localhost.get(`/production/print-label?serial=${producedDevice.serial}&deviceCode=${device.code}&copies=6&layout=label_layout_one&printName=${printerNameOne}`)
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
          });
      }

      if (printerNameTwo) {
        this.steps("Printing medium label...", 40);
        await localhost.get(`/production/print-label?serial=${producedDevice.serial}&deviceCode=${device.code}&copies=1&layout=label_layout_two&printName=${printerNameTwo}`)
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
          });
      }

      // Generates network keys
      this.steps("Generating network keys...", 45);
      const networkKeys = await api.post(`/v1/production/${producedDevice.id}/generate-network-keys`)
        .then(({ data }) => { return data.item })
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
        });

      // Build network commands
      const networkCommands = await this.buildNetworkCommands(networkKeys);
      this.steps("Network keys generated!", 50);

      // Install network keys on device
      this.steps("Configuring network keys on the device...", 55);
      await localhost.post(`/production/send-commands`, { commands: networkCommands })
        .then(({ data }) => { return data.item })
        .catch((error) => {
          throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        });

      this.steps("Network keys configurated!", 60);

      if (!!staticCommands.length) {
        await this.steps("Configurating Static Params...", 65);
        await localhost.post(`/production/send-commands`, { commands: staticCommands })
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
          });
      }

      if (!!deviceRestartCommand.length) {
        await this.steps("Restarting Device...", 80);
        await localhost.post(`/production/send-commands`, { commands: deviceRestartCommand })
          .catch((error) => {
            throw (error.message === "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
          });
      }

      this.steps("All commands were executed successfully. The device has been configured!", 100);

      this.setState({ visibleModalTest: true });

    } catch (error) {
      let dataError = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : error;
      let message = dataError.message ? dataError.message : error.toString();
      let errorCode = dataError.code ? dataError.code : 500;
      this.steps(message, 100, false);

      const { producedDeviceId } = this.state;
      if (producedDeviceId) {
        await this.endProduction(false, dataError.payload);
      }

      this.openModalResult(false, message, errorCode);
    }
  }

  timeLineItems() {
    return this.state.timeLineFlow.map(item => this.timeLineItem(item));
  }

  timeLineItem(item) {
    const { msg, success } = item;
    const color = success ? "green" : "red";

    return (
      <Timeline.Item key={item.msg} color={color}>{msg}</Timeline.Item>
    );
  }

  async buildNetworkCommands(networkKeys) {
    const { networkCommands } = this.state;
    networkCommands.forEach((command) => {
      if (command.name === "activationType") {
        command.hexPayload = networkKeys[command.name] === "OTAA" ? ["0x01"] : ["0x00"];
      } else {
        command.hexPayload = Buffer.from(networkKeys[command.name], "hex");
      }
    });
    return networkCommands;
  }

  getProductionLine(productionLineId) {
    const { api } = this;

    api.get(`/v1/production-lines/${productionLineId}`).then(async (response) => {
      const { printerNameOne, printerNameTwo, productionParams: { device }, name: productionLineName } = response.data.item;
      await this.getDeviceCommands(device.id);
      this.setState({ device, productionLineName, printerNameOne, printerNameTwo });
    }).catch((error) => {
      let newError = (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
      this.openModalResult(false, newError.message, newError.code);
    });
  }

  async getDeviceCommands(deviceId) {
    const { api } = this;
    api.get(`/v1/devices/${deviceId}/commands`)
      .then(({ data }) => {

        const { commands } = data.item;
        this.setState({
          networkCommands: commands.filter(({ type }) => type === "NETWORK"),
          generalCommands: commands.filter(({ type }) => type === "GENERAL"),
          staticCommands: commands.filter(({ type }) => type === "STATIC"),
          testCommands: commands.filter(({ type }) => type === "TEST"),
        });

        const { generalCommands, testCommands } = this.state;
        this.setState({
          deviceTypeCommand: generalCommands.filter(({ name }) => name === "deviceType"),
          deviceRestartCommand: generalCommands.filter(({ name }) => name === "deviceRestart"),
          deviceInternalMeter: testCommands.filter(({ name }) => name === "readActualActivePowerInternalMeter"),
        });
      }).catch((error) => {
        let newError = (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
        this.openModalResult(false, newError.message, newError.code);
      });
  }

  async steps(msg, percentUpload, success = true, items = []) {
    this.state.timeLineFlow.push({ msg, success, items });
    this.setState({ percentUpload });
    if (percentUpload === 100) {
      this.setState({ buttonStartConfiguration: false });
    }
  }

  openModalResult(success, message, errorCode = 500) {
    this.setState({ visibleModalResult: true, productionStatus: { message, errorCode, success } });
  }

  handleCancel() {
    this.setState({
      visibleModalLine: false,
      visibleModalTest: false,
      visibleModalResult: false,
      buttonStartConfiguration: false,
      timeLineFlow: [],
      totalVirtualAssetToProduce: 0,
    });
  }

  handleSave = (productionLineId) => {
    localStorage.setItem("@Urbana:productionLineId", productionLineId);
    this.setState({ productionLineId });
    this.getProductionLine(productionLineId);
    this.handleCancel();
  };

  handleCloseModalTest = async (success, message, error, deviceCommandError = null) => {
    this.handleCancel();
    await this.endProduction(success, deviceCommandError);

    const { visibleModalResult } = this.state;
    if (!visibleModalResult) {
      this.openModalResult(success, message, error);
    }
  }

  async endProduction(success, deviceCommand = null) {
    const { localhost, api } = this;
    const { producedDeviceId } = this.state;

    const endProductionDate = await localhost.get("/production/end")
      .then(({ data }) => data.item.endProductionDate)
      .catch(() => {
        let newError = ErrorMap.LOCAL_SYSTEM_ERROR
        console.log(newError)
      })

    const body = {
      endProduction: endProductionDate || new Date(),
      success,
    }

    if (deviceCommand) {
      body.deviceCommandError = deviceCommand;
    }

    await api.post(`/v1/production/${producedDeviceId}/end`, body).catch((error) => {
      let newError = (error.message === "Network Error") ? ErrorMap.REMOTE_SYSTEM_ERROR : ErrorMap.API_CALL_ERROR
      this.openModalResult(false, newError.message, newError.code);
    });
  }

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({ [name]: value });
    this.setState({ buttonSave: false });
  }

  render() {
    const {
      visibleModalLine,
      visibleModalTest,
      visibleModalResult,
      productionLineId,
      productionLineName,
      device,
      percentUpload,
      buttonStartConfiguration,
      testCommands,
      productionStatus,
      manager,
      totalVirtualAssetToProduce,
    } = this.state;

    let modalLine;
    let modalTest;
    let modalResult;

    if (visibleModalLine) {
      modalLine = <ModalLine
        productionLineId={productionLineId}
        onSaveCallback={this.handleSave}
        onCancelCallback={this.handleCancel}
      />
    }

    if (visibleModalResult) {
      modalResult = <ModalResult
        productionStatus={productionStatus}
        onCloseModal={this.handleCancel}
      />
    }

    if (visibleModalTest) {
      modalTest = <ModalTest
        testCommands={testCommands}
        title={"Device Test"}
        onCloseModalTest={this.handleCloseModalTest}
      />
    }
    return (
      <Layout>
        <div style={styles.wrapper}>
          <span style={styles.span}>{productionLineName}</span>
          {modalLine}
          {modalTest}
          {modalResult}
          <Form style={styles.form}>
            <Item label="Device">
              <Input
                value={device.code}
                size="large"
                disabled
              />
            </Item>

            <br />
            <Button
              hidden={device.model === 'QRCODE'}
              style={styles.button}
              type="primary"
              disabled={buttonStartConfiguration}
              onClick={this.handleStartConfiguration}>Start configuration
            </Button>
            <Item
              hidden={device.model != 'QRCODE'}
              label="Number of virtual assets to produce">

              <Input
                hidden={device.model != 'QRCODE'}
                name="totalVirtualAssetToProduce"
                value={totalVirtualAssetToProduce}
                onChange={this.onChange}
                size="large"
                type="number"
                min="0"
              />
            </Item>
            <Button
              hidden={device.model != 'QRCODE'}
              style={styles.button}
              type="primary"
              disabled={totalVirtualAssetToProduce == 0 || buttonStartConfiguration}
              onClick={this.handleProduceVirtualAsset}>Produce Virtual Asset
            </Button>
            <br />

            <Item key={1} label="">
              <Progress
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "#87d068",
                }}
                percent={percentUpload}
              />
            </Item>
            <Timeline disabled={manager}>
              {this.timeLineItems()}
            </Timeline>
          </Form>

        </div>
      </Layout>
    );
  }
}

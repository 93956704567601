import React, { Component } from "react";
import { Form, Button } from "antd";
import styles from "./styles";

export default class Home extends Component {
  state = {
    selectedOption: null,
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleButton = e => {
    e.preventDefault();
    this.props.history.push("/login", e.currentTarget.value);
  }

  render() {
    return (
      <div style={styles.homeWrapper}>
        <Form style={styles.homeForm}>
          <Button
            style={styles.button}
            value="manager"
            type="primary"
            onClick={this.handleButton}>
            Production Configuration
          </Button>
          <br />
          <br />
          <br />
          <Button
            style={styles.button}
            value="operator"
            type="primary"
            onClick={this.handleButton}>
            Start Production
          </Button>
        </Form>
      </div>

    );
  }
}

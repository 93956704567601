export default ({
  wrapper: {
    height: "65%",
    display: "flex",
    margin: "70px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  form: {
    margin: "20px 0 0",
    width: "95%",

    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },

  input: {
    height: "80px",
    fontSize: "18px",
  },

  button: {
    height: "70px",
    fontSize: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "2px"
  },

  span: {
    color: "#8a8a8a",
    fontSize: "70px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "5px",
    margin: "10px"
  },
})
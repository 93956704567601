export const ErrorMap = {
  LOCAL_SYSTEM_ERROR: {
    message: "Local system error",
    code: 100
  },
  REMOTE_SYSTEM_ERROR: {
    message: "Remote system error",
    code: 103
  },
  API_CALL_ERROR: {
    message: "API call error",
    code: 201
  },
};
import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import TopNavBar from './components/TopNavBar';
import Login from './containers/Login';
import Home from './containers/Home';
import Manager from './containers/Manager';
import Production from './containers/Production';
import User from './containers/User';
import Line from './containers/Line';
import "antd/dist/antd.css";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute path="/manager" component={Manager} />
          <PrivateRoute path="/users" component={User} />
          <PrivateRoute path="/production-lines" component={Line} />
          <PrivateRoute path="/production" component={Production} />
          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  const jwt = localStorage.getItem('@Urbana:jwt')

  return (
    <Route
      {...rest}
      render={props =>
        (jwt) ? (
          <div>
            <TopNavBar {...props} />
            <Component {...props} />
          </div>
        ) : (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
}

export default App;

export default ({
  stepsContent: {
    marginTop: "16px",
    border: "1px dashed #e9e9e9",
    bordeRadius: "6px",
    backgroundColor: "#fafafa",
    minHeight: "200px",
    textAlign: "center",
    paddingTop: "80px"
  },
  button: {
    width: "200px",
    height: "50px",
  }
})
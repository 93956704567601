import React, { Component } from "react";
import { Input, Button, Modal, Form, List, Checkbox } from "antd";
import styles from "./styles";
const { Item } = List;

export default class ModalLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      printerNameOne: "",
      printerNameTwo: "",
      active: true
    }
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSave() {
    const { onSaveCallback, modalAction } = this.props
    const { id, name, active, printerNameOne, printerNameTwo } = this.state;
    onSaveCallback(modalAction, id, name, active, printerNameOne, printerNameTwo)
  };

  handleCancel() {
    const { onCancelCallback } = this.props
    onCancelCallback()
  };

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onChangeActive = (event) => {
    event.preventDefault();
    this.setState({
      active: event.target.checked,
    });
  }

  componentDidMount() {
    const { line } = this.props;

    this.setState({ id: line.id });
    this.setState({ name: line.name });
    this.setState({ printerNameOne: line.printerNameOne });
    this.setState({ printerNameTwo: line.printerNameTwo });
    this.setState({ active: line.active !== undefined ? line.active : true });
  }

  render() {
    const { id, name, active, printerNameOne, printerNameTwo } = this.state;
    const { modalTitle } = this.props;

    return (
      <Modal
        title={modalTitle}
        visible={true}
        onCancel={this.handleCancel}
        footer={[
          <Button key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={this.handleSave}>
            Save
          </Button>
        ]}
      >
        <Form style={styles.form}>
          <span>ID:</span>
          <Item>
            <Input
              value={id}
              disabled
              size="large">
            </Input>
          </Item>
          <span>Name:</span>
          <Item>
            <Input
              name="name"
              onChange={this.onChange}
              value={name}
              size="large">
            </Input>
          </Item>
          <span>Printer Name One (layout label one):</span>
          <Item>
            <Input
              name="printerNameOne"
              onChange={this.onChange}
              value={printerNameOne}
              size="large"
            >
            </Input>
          </Item>
          <span>Printer Name Two (layout label two):</span>
          <Item>
            <Input
              name="printerNameTwo"
              onChange={this.onChange}
              value={printerNameTwo}
              size="large"
            >
            </Input>
          </Item>
          <span>Active:</span>
          <Item>
            <Checkbox
              name="active"
              onChange={this.onChangeActive}
              checked={active}
            >
            </Checkbox>
          </Item>
        </Form>
      </Modal>
    );
  }
}

import React, { Component } from "react";
import { Form, Select, Button, Layout, Input, Row, Col, message, Carousel, Icon, Empty, Badge } from "antd";
import { ApiService } from "../../services/ApiService";
import _ from "lodash";
import moment from "moment";
import styles from "./styles";

const { Option } = Select;
const { Item } = Form;

export default class ManagerConf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usbList: [],
      deviceList: [],
      firmware: "",
      deviceName: null,
      deviceCode: null,
      selectedDevice: null,
      orderNumber: "",
      rowNumber: null,
      shippingNumber: null,
      buttonSave: false,
      buttonPrintDisabled: false,
      lines: [],
      line: {},
    };

    this.carousel = React.createRef();
    this.onSaveParams = this.onSaveParams.bind(this);
    this.onLineChange = this.onLineChange.bind(this);
    this.handleTestPrint = this.handleTestPrint.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.api = ApiService();
    this.localhost = ApiService({ baseURL: "http://localhost:3004" });
  }

  onChangeDevice = async (selectedDevice) => {
    const { deviceList } = this.state;
    this.setState({ selectedDevice });
    this.setState({ buttonSave: false });

    deviceList.map(device => {
      if (device.id.toString() === selectedDevice) {
        this.setState({ deviceCode: device.code, firmware: device.firmwareName, deviceName: device.name })
      }
    });
  };

  async handleTestPrint(line) {
    const { localhost } = this;
    const { deviceCode } = this.state;

    this.setState({ buttonPrintDisabled: true });
    try {
      if (line.printerNameOne) {
        await localhost.get(`/production/print-label?serial=123456789&deviceCode=${deviceCode}&copies=1&layout=label_layout_one&printName=${line.printerNameOne}`)
      }
      if (line.printerNameTwo) {
        await localhost.get(`/production/print-label?serial=123456789&deviceCode=${deviceCode}&copies=1&layout=label_layout_two&printName=${line.printerNameTwo}`)
      }
      this.setState({ buttonPrintDisabled: false });
    } catch (error) {
      let message = (error.response) ? error.response.data.error.message : error.toString();
      this.openModalResult(false, message);
    }
  }

  componentDidMount() {
    const { api } = this;

    try {
      api.post("/v1/devices-list").then((response) => {
        this.setState({ deviceList: response.data.page.items }, () => {
          api.get("/v1/production-lines").then((response) => {
            const { items } = response.data;
            this.setState({ lines: items }, () => {
              this.carousel.innerSlider.state.currentSlide = 0;
              this.onLineChange(0);
            });
          })
        });
      })
    } catch (error) {
      var msg = (error.response) ? error.response.data.errors : error.toString()
      message.error(msg);
    }
  }

  onSaveParams(productionLineId) {
    const { api } = this;
    const { selectedDevice, orderNumber, rowNumber, shippingNumber, lines } = this.state

    var body = {
      deviceId: selectedDevice,
      orderNumber,
      rowNumber,
      shippingNumber,
      productionLineId
    }

    api.post("/v1/production-params", body).then((response) => {
      const { item, item: { productionLineId } } = response.data;
      lines.forEach(line => {
        if (line.id === productionLineId) {
          line.productionParams = item
        }
      })

      message.success("Parameters successfully saved");
      this.setState({ buttonSave: true });
    }).catch((error) => {
      var msg = (error.response) ? error.response.data.error.message : error.toString()
      message.error(msg);
    });
  }

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({ [name]: value });
    this.setState({ buttonSave: false });
  }

  onLineChange(index) {
    const line = this.state.lines[index];

    let deviceId = null;
    let orderNumber = null;
    let rowNumber = null;
    let shippingNumber = null;

    if (index >= 0) {
      if (!!line && line.productionParams) {
        deviceId = line.productionParams.deviceId.toString();
        orderNumber = line.productionParams.orderNumber;
        rowNumber = line.productionParams.rowNumber;
        shippingNumber = line.productionParams.shippingNumber
      }
      this.onChangeDevice(deviceId);
      this.setState({ orderNumber, rowNumber, shippingNumber })
    }
  }

  next() {
    this.carousel.next();
  }

  previous() {
    this.carousel.prev();
  }

  render() {
    const {
      selectedDevice,
      firmware,
      deviceName,
      deviceList,
      orderNumber,
      rowNumber,
      shippingNumber,
      buttonSave,
      lines,
      buttonPrintDisabled,
    } = this.state;

    return (
      <Layout>
        {lines.length ? "" : <Empty
          description="No production line configured"
          style={{ margin: "100px" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />}
        <div>
          <div style={styles.carouselTable}>
            <div style={styles.carouselItem} >
              <Button
                onClick={this.previous}
                type="link"
                style={{ "zIndex": "1" }}
              >
                <Icon style={{ "fontSize": "40px" }} type="left" />
              </Button>

            </div>
            <div style={{ ...styles.carouselItem, "textAlign": "end", "textAlign": "right" }}>
              <Button
                onClick={this.next}
                type="link"
                style={{ "zIndex": "1" }}
              >
                <Icon style={{ "fontSize": "40px", }} type="right" />
              </Button>
            </div>
          </div>
        </div>

        <Carousel
          dots={false}
          afterChange={this.onLineChange}
          ref={node => (this.carousel = node)}
        >
          {
            lines.map(line => {
              return (
                <h3>
                  <div style={styles.wrapper}>
                    <span style={styles.description}>{line.name}{line.active}</span>
                    <span style={styles.lineInfo}>{`Printer One: ${line.printerNameOne || "Not Set"} | Printer Two: ${line.printerNameTwo || "Not Set"}`}</span>
                    <span style={styles.lineInfo}>{`Created: ${moment(line.createdAt).format("MM/DD/YYYY, h:mm:ss")}`}</span>
                    <Badge text={line.active ? "Active" : "Not Active"} status={line.active ? "success" : "warning"} />
                    <br />
                    <Button
                      disabled={buttonPrintDisabled}
                      onClick={() => this.handleTestPrint(line)}
                      type="default"
                      icon="printer"
                    >Test Print
                    </Button>
                    <Form style={styles.form}>
                      <Item label="Device">
                        <Select
                          value={selectedDevice}
                          onChange={this.onChangeDevice}
                          size="large"
                        >
                          {
                            deviceList.map(option =>
                              <Option key={option.id}>{option.code}</Option>)
                          }
                        </Select>
                      </Item>

                      <Item label="Device Name">
                        <Row gutter={8}>
                          <Col span={18}>
                            <Input
                              name="deviceName"
                              value={deviceName}
                              disabled
                              size="large">
                            </Input>
                          </Col>
                          <Col span={6}>
                            <Input
                              name="firmware"
                              value={firmware}
                              disabled
                              addonBefore="Firmware"
                              size="large" />
                          </Col>
                        </Row>
                      </Item>

                      <Item label="Order Number">
                        <Row gutter={8}>
                          <Col span={18}>
                            <Input
                              name="orderNumber"
                              value={orderNumber}
                              onChange={this.onChange}
                              size="large">

                            </Input>
                          </Col>
                          <Col span={6}>
                            <Input
                              name="rowNumber"
                              value={rowNumber}
                              onChange={this.onChange}
                              type="number"
                              min="0"
                              addonBefore="Row Number"
                              size="large" />
                          </Col>
                        </Row>
                      </Item>
                      <Item label="Shipping Number">
                        <Input
                          name="shippingNumber"
                          value={shippingNumber}
                          onChange={this.onChange}
                          size="large"
                        />
                      </Item>
                      <br />
                      <Button
                        style={styles.button}
                        type="primary"
                        disabled={buttonSave}
                        onClick={() => this.onSaveParams(line.id)}>
                        Save
                    </Button>
                    </Form>
                    <div style={styles.lineInfo}>{this.carousel.innerSlider.state.currentSlide + 1} of {lines.length}</div>
                  </div>
                </h3>
              )
            })
          }
        </Carousel>
      </Layout >
    );
  }
}

import React, { Component } from "react";
import { Modal, Result, Button } from "antd";
import styles from "./styles";

export default class ModalResult extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleCloseModal = () => {
    const { onCloseModal } = this.props;
    onCloseModal()
  }

  render() {
    const { productionStatus: { message, errorCode, success } } = this.props;
    let resultMessage = !!success ? <Result
      status={"success"}
      title="Success!"
      subTitle={message}
      extra={[
        <Button
          style={styles.button}
          type="primary"
          key="success"
          onClick={this.handleCloseModal}
        >
          New Device
        </Button>,
      ]}
    /> :
      <Result
        status={"error"}
        title={`Error Code: ${errorCode}`}
        subTitle={!!message ? message : "Discard the Device!"}
        extra={[
          <Button
            style={styles.button}
            type="primary"
            key="error"
            onClick={this.handleCloseModal}
          >
            New Device
          </Button>
        ]}
      />
    return (
      <Modal
        width={1300}
        title="Result"
        visible={true}
        footer={null}
        closable={false}
      >
        {resultMessage}
      </Modal >
    );
  }
}

import React, { Component } from "react";
import { Modal, Steps, Button, Icon } from "antd";
import { ApiService } from "../../../services/ApiService";
import styles from "./styles";
import { ErrorMap } from "../../../utils/Errors"
const { Step } = Steps;

export default class ModalTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTest: 0,
      processingTest: false,
      startTestButtonDisabled: false,
      stepContentManualTest: false,
      testCommandsManual: [],
      testCommandsAutomatic: [],
      commandResponse: null
    }
    this.handleStartManualTest = this.handleStartManualTest.bind(this);
    this.handleStartAutomaticTest = this.handleStartAutomaticTest.bind(this);
    this.handleActionButtonTest = this.handleActionButtonTest.bind(this);
    this.localhost = ApiService({ baseURL: "http://localhost:3004" });
  }

  async handleStartManualTest() {
    const { localhost } = this;

    const { currentTest, testCommandsManual } = this.state;

    this.setState({ startTestButtonDisabled: true })
    if (!testCommandsManual.length) {
      return this.handleStartAutomaticTest();
    }

    this.setState({ processingTest: true })
    await localhost.post(`/production/send-command`, { command: testCommandsManual[currentTest] })
      .then(({ data }) => {
        this.setState({
          commandResponse: data.item,
          stepContentManualTest: true,
          processingTest: false,
        });
      }).catch((error) => {
        let newError = (error.message == "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        this.handleErrorModalTest(newError);
      });
  };

  handleErrorModalTest(error) {
    const { onCloseModalTest } = this.props;
    this.setState({ processingTest: false });

    let dataError = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : error;
    let message = dataError.message ? dataError.message : error.toString();
    let errorCode = dataError.code ? dataError.code : 500;

    onCloseModalTest(false, message, errorCode, dataError.payload);
  }

  async handleStartAutomaticTest() {
    const { localhost } = this;
    const { onCloseModalTest } = this.props;
    const { testCommandsAutomatic } = this.state;

    this.setState({ processingTest: true });
    await localhost.post(`/production/send-commands`, { commands: testCommandsAutomatic }, { timeout: 400000 })
      .then(({ data }) => {
        onCloseModalTest(true);
      }).catch((error) => {
        let newError = (error.message == "Network Error") ? ErrorMap.LOCAL_SYSTEM_ERROR : error
        this.handleErrorModalTest(newError);
      });
  };

  handleActionButtonTest = (event) => {
    const { onCloseModalTest } = this.props;
    const { currentTest, testCommandsManual, commandResponse } = this.state;
    const success = event.target.value === "true" ? true : false;
    this.setState({ stepContentManualTest: false });

    if (!success) {
      commandResponse.userValue = event.target.value;
      onCloseModalTest(false, commandResponse);
    } else {
      this.setState({ currentTest: (currentTest + 1) }, () => {
        const { currentTest } = this.state;
        if ((currentTest) === testCommandsManual.length) {
          this.handleStartAutomaticTest();
        } else {
          this.handleStartManualTest();
        }
      });
    }
  }

  componentDidMount() {
    const { testCommands } = this.props;
    const testCommandsManual = testCommands.filter(testCommand => testCommand.deviceCommands[0].testType === "MANUAL");
    const testCommandsAutomatic = testCommands.filter(testCommand => testCommand.deviceCommands[0].testType === "AUTOMATIC");
    this.setState({ testCommandsManual, testCommandsAutomatic });
  }

  render() {
    const { title } = this.props;
    const { currentTest, processingTest, stepContentManualTest, testCommandsManual, startTestButtonDisabled } = this.state;
    const testConfig = testCommandsManual[currentTest] ? testCommandsManual[currentTest].deviceCommands[0].testConfig : null;

    let testOptions = !!testConfig ?
      <div style={{
        pointerEvents: !!stepContentManualTest ? "auto" : "none",
        opacity: !!stepContentManualTest ? 1 : 0.4,
      }}>
        <div style={styles.stepsContent} >
          <h1>{testConfig.title}</h1>
          {
            testConfig.buttons.map(button => {
              return (
                <Button
                  value={button.value}
                  type="default"
                  onClick={this.handleActionButtonTest}
                  style={{
                    width: "200px",
                    height: "100px",
                    marginBottom: "30px",
                    marginLeft: "10px",
                    borderColor: "transparent",
                    backgroundColor: button.color,
                    color: "white"
                  }}>
                  {button.text}
                </Button>
              )
            })
          }
        </div>
      </div> : null;

    return (
      <Modal
        width={1300}
        title={title}
        visible={true}
        closable={false}
        footer={[
          <Button
            key="save"
            type="primary"
            style={styles.buttonStartTest}
            disabled={startTestButtonDisabled}
            onClick={this.handleStartManualTest}
          >
            Start Test
          </Button>
        ]}
      >
        <Steps current={currentTest}>
          {
            testCommandsManual.map((testCommand, index) => {
              return (
                <Step
                  icon={(index === currentTest && !!processingTest) ? <Icon type="loading" /> : ""}
                  key={testCommand.id}
                  title={`Step ${index}`}
                  description={testCommand.description}
                />
              )
            })
          }
          <Step
            icon={((!testCommandsManual.length || currentTest === testCommandsManual.length) && !!processingTest) ? <Icon type="loading" /> : ""}
            title="Automatic tests"
          />
        </Steps>
        {testOptions}
      </Modal >
    );
  }
}

import React, { Component } from "react";
import { Input, Button, Modal, Form, List } from "antd";
import styles from "./styles";
const { Item } = List;

export default class ModalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      username: "",
      password: ""
    }
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSave() {
    const { onSaveCallback, modalAction } = this.props
    const { id, name, username, password } = this.state;
    onSaveCallback(modalAction, id, name, username, password)
  };

  handleCancel() {
    const { onCancelCallback } = this.props
    onCancelCallback()
  };

  onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({ id: user.id });
    this.setState({ name: user.name });
    this.setState({ username: user.username });
  }

  render() {
    const { id, name, username, password } = this.state;
    const { modalAction, modalTitle } = this.props;

    return (
      <Modal
        title={modalTitle}
        visible={true}
        onCancel={this.handleCancel}
        footer={[
          <Button key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={this.handleSave}>
            Save
          </Button>,
        ]}
      >
        <Form style={styles.form}>
          <span>ID:</span>
          <Item >
            <Input
              placeholder="ID"
              value={id}
              disabled
              size="large">
            </Input>
          </Item>
          <span>Name:</span>
          <Item label="Name">
            <Input
              placeholder="Name"
              name="name"
              disabled={modalAction === "password" ? true : false}
              onChange={this.onChange}
              value={name}
              size="large">
            </Input>
          </Item>
          <span>Username:</span>
          <Item label="Username">
            <Input
              placeholder="Username"
              name="username"
              disabled={modalAction === "password" ? true : false}
              onChange={this.onChange}
              value={username}
              size="large">
            </Input>
          </Item>
          <span>Password:</span>
          <Item label="Password">
            <Input
              placeholder={modalAction === "edit" ? "*******" : "Insert new password"}
              name="password"
              type="password"
              disabled={modalAction === "edit" ? true : false}
              onChange={this.onChange}
              value={password}
              size="large">
            </Input>
          </Item>
        </Form>
      </Modal>

    );
  }
}

export default ({
  header: {
    padding: 0,
    backgroundColor: '#FFF',
    borderBottom: '1px solid rgb(0,0,0,0.1)',
  },

  menu: {
    lineHeight: '62px',
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  factoryText: {
    // color: "#8a8a8a",
    fontSize: "15px",
    fontWeight: "bold",
    marginLeft: "10px"
  },
});
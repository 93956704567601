import React, { Component } from "react";
import { Breadcrumb, Button, Modal, Form, Layout, message, List, Avatar, Skeleton, Icon } from "antd";
import { ApiService } from "../../services/ApiService";
import ModalUser from "./ModalUser"
import styles from "./styles";

const { confirm } = Modal;
const { Item } = List;

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: {},
      visibleModalUser: false,
      modalAction: "",
      modalTitle: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.api = ApiService();
  }

  componentDidMount() {
    const { api } = this;

    api.get("/v1/users").then((response) => {
      this.setState({ users: response.data.items });
    }).catch((error) => {
      var msg = (error.response) ? error.response.data.error.message : error.toString()
      message.error(msg);
    });
  }

  onDelete = (user) => {
    const { api } = this;
    const { users } = this.state;
    const _this = this

    confirm({
      title: "Do you want to delete this user?",
      content: `${user.name}`,
      onOk() {
        api.delete(`/v1/users/${user.id}`).then(() => {
          const usersEdited = users.filter(({ id }) => id !== user.id)
          _this.setState({ users: usersEdited })
          message.success("User deleted!");
        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
      },
      onCancel() { },
    });
  }

  showModalUser = (user = {}, modalAction, modalTitle) => {
    this.setState({
      visibleModalUser: true,
      modalAction,
      modalTitle,
      user
    });
  };

  handleSave = (modalAction, userId, name, username, password) => {
    const { users } = this.state;
    const { api } = this;
    let body = {}
    switch (modalAction) {
      case "edit":
        body = {
          name,
          username
        }

        api.put(`/v1/users/${userId}`, body).then(() => {
          users.forEach(user => {
            if (user.id === userId) {
              user.name = name;
              user.username = username;
            }
          })

          message.success("User edited!");
          this.handleCancel()

        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
        break;
      case "insert":
        body = {
          name,
          username,
          password
        }
        api.post(`/v1/users`, body).then((response) => {
          users.push({ id: response.data.item.id, name, username })

          message.success("User created!");
          this.handleCancel()

        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
        break
      case "password":
        body = {
          password
        }
        api.put(`/v1/users/${userId}/change-password`, body).then(() => {
          message.success("Password changed!");
          this.handleCancel()

        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
        break
      default:
        break;
    }
  };

  handleCancel = () => {
    this.setState({
      visibleModalUser: false,
    });
  };

  render() {
    const { users, user, visibleModalUser, modalAction, modalTitle } = this.state;
    let modalUser;

    if (visibleModalUser) {
      modalUser = <ModalUser
        modalAction={modalAction}
        modalTitle={modalTitle}
        user={user}
        onSaveCallback={this.handleSave}
        onCancelCallback={this.handleCancel} />
    }
    return (
      <Layout>
        <div style={styles.wrapper}>
          <Breadcrumb>
            <Breadcrumb.Item >Home</Breadcrumb.Item>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
          </Breadcrumb>

          <Form style={styles.form}>
            <List
              header={
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button type="primary" onClick={() => this.showModalUser({}, "insert", "New User")}>New User</Button>
                </div>
              }
              bordered
              dataSource={users}
              renderItem={user => (
                <Item
                  actions={[
                    <Icon
                      style={{ fontSize: "18px" }}
                      key="edit"
                      type="edit"
                      onClick={() => this.showModalUser(user, "edit", "Edit User")}
                    />,
                    <Icon
                      style={{ fontSize: "18px" }}
                      key="password"
                      type="key"
                      onClick={() => this.showModalUser(user, "password", "Change Password")}
                    />,
                    <Icon
                      style={{ fontSize: "18px" }}
                      onClick={() => this.onDelete(user)} key="edit" type="delete"
                    />
                  ]}
                >
                  <Skeleton avatar title={false} loading={false} active>
                    <Item.Meta
                      avatar={
                        <Avatar size="large" icon="user" />
                      }
                      title={user.name}
                      description={user.username}
                    />
                  </Skeleton>
                </Item>
              )}
            />
            {modalUser}
          </Form>
        </div>
      </Layout >
    );
  }
}

export default UserForm;
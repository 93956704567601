export default ({
  loginWrapper: {
    height: "65%",
    display: "flex",
    
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  loginForm: {
    margin: "20px 0 0",
    width: "50%",

    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },

  input: {
    height: "70px",
    fontSize: "18px",
  },

  button: {
    height: "70px",
    fontSize: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "2px"
  },

  span: {
    color: "#e6e6e6",
    fontSize: "70px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "5px",
    margin: "10px"
  },

  buttonBack: {
    margin: "40px",
    fontSize: "60px",
    justifyContent: "flex-start",
  }
})
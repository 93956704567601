import React, { Component } from "react";
import { Breadcrumb, Button, Modal, Form, Layout, message, List, Avatar, Skeleton, Icon } from "antd";
import { ApiService } from "../../services/ApiService";
import ModalLine from "./ModalLine"
import moment from "moment";
import styles from "./styles";
const { confirm } = Modal;
const { Item } = List;

class LineForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: [],
      line: {},
      visibleModalLine: false,
      modalAction: "",
      modalTitle: ""
    };

    this.onDelete = this.onDelete.bind(this);
    this.api = ApiService();
  }

  componentDidMount() {
    const { api } = this;

    api.get("/v1/production-lines").then((response) => {
      this.setState({ lines: response.data.items });
    }).catch((error) => {
      var msg = (error.response) ? error.response.data.error.message : error.toString()
      message.error(msg);
    });
  }

  onDelete = (line) => {
    const { api } = this;
    const { lines } = this.state;
    const _this = this

    confirm({
      title: "Do you want to delete this line?",
      content: `${line.name}`,
      onOk() {
        api.delete(`/v1/production-lines/${line.id}`).then(() => {
          _this.setState({ lines: lines.filter(({ id }) => id !== line.id) })
          message.success("Line deleted!");
        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
      },
      onCancel() { },
    });
  }

  showModalLine = (line = {}, modalAction, modalTitle) => {
    this.setState({
      visibleModalLine: true,
      modalAction,
      modalTitle,
      line
    });
  };

  handleSave = (modalAction, lineId, name, active, printerNameOne, printerNameTwo) => {
    const { lines } = this.state;
    const { api } = this;
    let body = {}

    switch (modalAction) {
      case "edit":
        body = {
          name,
          active,
          printerNameOne,
          printerNameTwo,
        }
        api.put(`/v1/production-lines/${lineId}`, body).then(() => {
          lines.forEach(line => {
            if (line.id === lineId) {
              line.name = name;
              line.active = active;
              line.printerNameOne = printerNameOne;
              line.printerNameTwo = printerNameTwo;
            }
          })

          message.success("Line edited!");
          this.handleCancel()

        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
        break;
      case "insert":
        body = {
          name,
          active,
          printerNameOne,
          printerNameTwo,
        }
        api.post(`/v1/production-lines`, body).then((response) => {
          lines.push({ id: response.data.item.id, name, active, printerNameOne, printerNameTwo })

          message.success("Line created!");
          this.handleCancel()

        }).catch((error) => {
          var msg = (error.response) ? error.response.data.error.message : error.toString()
          message.error(msg);
        });
        break
      default:
        break;
    }
  };

  handleCancel = () => {
    this.setState({
      visibleModalLine: false,
    });
  };

  render() {
    const { lines, line, visibleModalLine, modalAction, modalTitle } = this.state;
    let modalLine;

    if (visibleModalLine) {
      modalLine = <ModalLine
        modalAction={modalAction}
        modalTitle={modalTitle}
        line={line}
        onSaveCallback={this.handleSave}
        onCancelCallback={this.handleCancel} />
    }
    return (
      <Layout>
        <div style={styles.wrapper}>
          <Breadcrumb>
            <Breadcrumb.Item >Home</Breadcrumb.Item>
            <Breadcrumb.Item>Lines</Breadcrumb.Item>
          </Breadcrumb>

          <Form style={styles.form}>
            <List
              header={
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    type="primary"
                    onClick={() => this.showModalLine({}, "insert", "New Line")}>New Production Line
                  </Button>
                </div>
              }
              bordered
              dataSource={lines}
              renderItem={line => (
                <Item
                  actions={[
                    <Icon
                      style={{ fontSize: "18px" }}
                      key="edit"
                      type="edit"
                      onClick={() => this.showModalLine(line, "edit", "Edit Line")}
                    />,
                    <Icon
                      style={{ fontSize: "18px" }}
                      onClick={() => this.onDelete(line)} key="edit" type="delete"
                    />
                  ]}
                >
                  <Skeleton avatar title={false} loading={false} active>
                    <Item.Meta
                      avatar={
                        <Avatar size="large" icon="flag" />
                      }
                      title={`${line.name} - ${line.active ? "Active" : "Not Active"}`}
                      description={`Created: ${moment(line.createdAt).format("MM/DD/YYYY, h:mm:ss")}`}
                    />
                  </Skeleton>
                </Item>
              )}
            />
            {modalLine}
          </Form>
        </div>
      </Layout >
    );
  }
}

export default LineForm;
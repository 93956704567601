export default ({
  homeWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  homeForm: {
    margin: "20px 0 0",
    width: "80%",

    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },

  button: {
    height: "200px",
    fontSize: "50px",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "2px",
  }
})
import React, { Component } from "react";
import { Form, Input, Button, Icon, Alert } from "antd";
import { ApiService } from "../../services/ApiService";
import styles from "./styles";
import { asyncLocalStorage } from "../../utils/LocalStorage"

const { Item } = Form;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      sourceOrigin: "",
      hasError: false,
      msgError: "",
    };
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.api = ApiService();
  }

  componentDidMount() {
    this.setState({ sourceOrigin: this.props.location.state });
  }

  handleUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  handleBack = e => {
    this.props.history.push("/home");
  }

  handleLogin = async (e) => {
    e.preventDefault();

    const { username, password, sourceOrigin } = this.state;
    const { api } = this;

    if (!username.length) return;

    var body = {
      username,
      password
    }

    api.post("/v1/authenticate", body).then(async (response) => {
      const { data: { manager, admin, factory, jwt } } = response;

      if (response.status === 200) {
        await asyncLocalStorage.setItem("@Urbana:jwt", jwt);
        await asyncLocalStorage.setItem("@Urbana:username", username);
        await asyncLocalStorage.setItem("@Urbana:manager", manager);

        if (factory) {
          await asyncLocalStorage.setItem("@Urbana:factory", factory);
        }

        if (sourceOrigin === "manager") {
          // Access only for admin or manager users
          if (manager || admin) {
            this.props.history.push("/manager");
          } else {
            this.setState({ msgError: "Restricted access user." });
            this.showAlert();
          }
        } else {
          this.props.history.push("/production");
        }

      }
    }).catch((error) => {
      var msg = (error.response) ? error.response.data.error.message : error.toString();
      this.setState({ msgError: msg });
      this.showAlert();
    });
  };

  showAlert() {
    this.setState({ hasError: true });
  }

  closeAlert() {
    this.setState({ hasError: false });
  }

  render() {
    const { msgError, hasError } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <Icon style={styles.buttonBack} type="arrow-left" onClick={this.handleBack}></Icon>
        <div style={styles.loginWrapper}>
          <Form style={styles.loginForm} onSubmit={this.handleLogin} align="middle">
            <span style={styles.span}>Login</span>
            <Item>
              {hasError && (
                <Alert
                  message="Ops!"
                  description={msgError}
                  type="error"
                  closable="true"
                  onClose={this.closeAlert}
                >
                </Alert>
              )}
            </Item>

            <Item>
              <Input
                prefix={<Icon type="user" style={{ paddingRight: "40px", color: "rgba(0,0,0,.25)" }} />}
                value={this.state.username}
                onChange={this.handleUsernameChange}
                type="username"
                placedolder="Username"
                style={styles.input}
                autoFocus
              />
            </Item>
            <Item>
              <Input
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                value={this.state.password}
                onChange={this.handlePasswordChange}
                type="password"
                placedolder="Password"
                style={styles.input}
              />
            </Item>
            <Button style={styles.button} type="primary" onClick={this.handleLogin}>Login</Button>
          </Form>
        </div >
      </div >
    );
  }
}

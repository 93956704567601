import axios from 'axios';

export const ApiService = (options = {}) => {
  const jwt = localStorage.getItem('@Urbana:jwt');
  const baseUrl = process.env.REACT_APP_API_URL;

  return axios.create({
    baseURL: baseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'authorization': `Bearer ${jwt}`
    },
    ...options
  });
}

import React, { PureComponent } from 'react';
import { Layout, Menu, Icon } from 'antd';
import styles from './styles';

class TopNavBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      current: 'params',
      factory: '',
      hidden: false,
      disabled: false
    }
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  onMenuClick = (pathname) => {
    const key = pathname.key ? pathname.key : pathname
    this.setState({ current: key });

    switch (key) {
      case "/manager":
        this.props.history.push("/manager");
        break;
      case "/production-lines":
        this.props.history.push("/production-lines");
        break;
      case "/users":
        this.props.history.push("/users");
        break;
      case "/production":
        this.setState({ hidden: true, disabled: true });
        break;
      case "logout":
        this.onClickLogout();
        break;
      default:
        break;
    }
  }

  onClickLogout() {
    localStorage.removeItem('@Urbana:jwt');
    localStorage.removeItem('@Urbana:username');
    localStorage.removeItem('@Urbana:factory');
    localStorage.removeItem('@Urbana:productionLineId');
    localStorage.removeItem('@Urbana:productionLineName');
    window.location.reload();
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    this.onMenuClick(pathname);
    const factory = localStorage.getItem('@Urbana:factory')
    // const username = localStorage.getItem('@Urbana:username')
    this.setState({ factory });
    // this.setState({ username });
  }

  render() {
    const { factory, hidden, disabled } = this.state;
    return (
      <Layout.Header style={styles.header}>
        <a href="#" disabled={disabled} style={styles.factoryText} onClick={() => this.onMenuClick('/manager')}>{factory} </a>
        <Menu
          mode="horizontal"
          style={styles.menu}
          selectedKeys={[this.state.current]}
          onClick={this.onMenuClick}
        >
          <Menu.Item hidden={hidden} key="/manager">
            <Icon type="control" />
            <span>Production Line Params</span>
          </Menu.Item>
          <Menu.Item hidden={hidden} key="/production-lines">
            <Icon type="interaction" />
            <span>Production Lines</span>
          </Menu.Item>
          <Menu.Item hidden={hidden} key="/users">
            <Icon type="usergroup-add" />
            <span>Users</span>
          </Menu.Item>
          <Menu.Item key="logout">
            <Icon type="logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Layout.Header >
    )
  }
}

TopNavBar.defaultProps = {
};

export default TopNavBar;
import React, { Component } from "react";
import { Modal, message, List, Icon } from "antd";
import { ApiService } from "../../../services/ApiService";
import moment from "moment";
const { Item } = List;

export default class ModalLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lines: []
    }
    this.handleSave = this.handleSave.bind(this)
    this.api = ApiService();
  }

  handleSave = (line) => {
    const { onSaveCallback } = this.props
    onSaveCallback(line.id)
  };

  componentDidMount() {
    const { api } = this;

    api.get("/v1/production-lines?onlyActive=true&withProductionParams=true").then((response) => {
      this.setState({ lines: response.data.items });
    }).catch((error) => {
      var msg = (error.response) ? error.response.data.error.message : error.toString()
      message.error(msg);
    });
  }

  render() {
    const { lines, selectedProdutionLidId } = this.state;

    return (
      <Modal
        title={"Select a production line"}
        visible={true}
        footer={null}
        closable={false}
      >
        <List
          locale={{ emptyText: "No Production Lines Configured" }}
          dataSource={lines}
          renderItem={line => (
            <Item
              style={{ cursor: "pointer" }}
              key={line.id}
              onClick={() => this.handleSave(line)}>
              <Item.Meta
                title={`${line.name} - ${line.active ? "Active" : "Not Active"}`}
                description={`Created: ${moment(line.createdAt).format("MM/DD/YYYY, h:mm:ss")}`}
              />
              {line.id === selectedProdutionLidId ? (
                <Icon
                  style={{ fontSize: "18px", color: "green" }}
                  key="edit"
                  type="check-circle"
                />) : ""
              }
            </Item>
          )}
        >
        </List>
      </Modal >
    );
  }
}
